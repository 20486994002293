<template>
  <div class="seaDetail-container">
    <el-drawer
      class="seaDetail-drawer"
      title="客户详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <el-form
            :model="editRuleForm"
            :rules="editRules"
            ref="editRuleForm"
            class="demo-ruleForm"
          >
            <div class="detail-title">
              <div class="tit-info hoverEdit" @click="() => {editBtn('name', customerInfo.name)}" v-if="editParams.filedName != 'name'">
                {{ (customerInfo && customerInfo.name) || "--" }}
              </div>
              <el-form-item v-if="editParams.filedName == 'name'" class="edit-input-container" prop="name">
                <el-input class="edit-input" ref="name" placeholder="请输入名称" v-model="editRuleForm.name" @blur="() => {editCancelBtn('name')}">
                  <template slot="append">
                    <div @click="() => {editSeaDetail('name')}" class="cancel">确认</div>
                  </template>
                </el-input>
              </el-form-item>
              <div style="display: flex;flex-direction: column;">
                <el-button class="common-screen-btn" @click="() => {$emit('custFishing', tableRow)}" type="primary">机会跟进</el-button>
                <div></div>
                <el-button class="common-screen-btn mt" @click="() => {$emit('goSea', tableRow)}" plain>打回公海</el-button>
              </div>
            </div>
            <div class="detail-content">
              <el-form-item
                v-if="editParams.filedName == 'areaId'"
                class="edit-input-container"
                prop="areaId" label="省市区:">
                <el-cascader ref="areaId" class="edit-input" placeholder="请选择" v-model="editRuleForm.areaId" @blur="() => {editCancelBtn('areaId')}" @change="(e) => {editSeaDetail('areaId')}" :props="props" filterable></el-cascader>
                <!-- <el-input ref="areaId" class="edit-input" placeholder="请输入内容" v-model="editRuleForm.areaId" @blur="() => {editCancelBtn('areaId')}">
                  <template slot="append" ><div @click="() => {editSeaDetail('areaId')}" class="cancel">确认</div></template>
                </el-input> -->
              </el-form-item>
              <div @click="() => { editBtn('areaId', customerInfo.areaId)}" v-if="editParams.filedName != 'areaId'" class="hoverEdit">
                省市区:
                <span class="editColor">{{ customerInfo && customerInfo.areaName || '--'}}</span>
              </div>
              <el-form-item
                v-if="editParams.filedName == 'address'"
                class="edit-input-container"
                prop="address"
                label="详细地址:"
              >
                <el-input
                 ref="address"
                  class="edit-input"
                  placeholder="请输入内容"
                  v-model="editRuleForm.address"
                  @blur="() => {editCancelBtn('address')}">
                  <template slot="append"
                    ><div @click="() => {editSeaDetail('address')}" class="cancel">确认</div></template
                  >
                </el-input>
              </el-form-item>
              <div @click="() => { editBtn('address', customerInfo.address)}" v-if="editParams.filedName != 'address'" class="hoverEdit">
                详细地址:
                <span class="editColor">{{(customerInfo && customerInfo.address) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'markingStatus'" class="edit-input-container" prop="markingStatus" label="打标:">
                <el-select class="edit-input" ref="markingStatus" v-model="editRuleForm.markingStatus" placeholder="请选择" @blur="() => {editCancelBtn('markingStatus')}" @change="(e) => {editSeaDetail('markingStatus')}" filterable>
                  <el-option v-for="item in custDict['8']" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <div @click="() => {editBtn('markingStatus', customerInfo.markingStatus)}" v-if="editParams.filedName != 'markingStatus'" class="hoverEdit">
                打标:
                <span class="editColor">{{(customerInfo && customerInfo.marking) || "--"}}</span>
              </div>
            </div>
            <div class="detail-content">
              <el-form-item v-if="editParams.filedName == 'level'" class="edit-input-container" prop="level" label="客户级别:">
                <el-select class="edit-input" ref="level" v-model="editRuleForm.level" placeholder="请选择" @blur="() => {editCancelBtn('level')}" @change="(e) => {editSeaDetail('level')}" filterable>
                  <el-option
                    v-for="item in custDict['1']"
                    :key="item.id + ''"
                    :label="item.name + ''"
                    :value="item.id + ''"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div
                @click="
                  () => {
                    editBtn('level', customerInfo.level);
                  }
                "
                v-if="editParams.filedName != 'level'"
                class="hoverEdit"
              >
                客户级别:
                <span class="editColor">{{
                  (customerInfo && customerInfo.levelName) || "--"
                }}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'industry'" class="edit-input-container" prop="industry" label="所属行业:">
                <el-select ref="industry" class="edit-input" v-model="editRuleForm.industry" placeholder="请选择" @blur="() => {editCancelBtn('industry')}" @change="(e) => {editSeaDetail('industry')}" filterable>
                  <el-option v-for="item in custDict['6']" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <div @click="() => { editBtn('industry', customerInfo.industry) }" v-if="editParams.filedName != 'industry'" class="hoverEdit">
                所属行业: <span class="editColor">{{(customerInfo && customerInfo.industryName) || "--"}}</span>
              </div>
              <el-form-item v-if="editParams.filedName == 'source'" class="edit-input-container" prop="source" label="客户来源:" >
                <el-select ref="source" class="edit-input" v-model="editRuleForm.source" placeholder="请选择" @blur="() => {editCancelBtn('source')}" @change="(e) => {editSeaDetail('source')}" filterable>
                  <el-option v-for="item in custDict['2']" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
              <div @click=" () => { editBtn('source', customerInfo.source) } " v-if="editParams.filedName != 'source'" class="hoverEdit" >
                客户来源: <span class="editColor">{{(customerInfo && customerInfo.sourceName) || "--"}}</span>
              </div>

              <!-- <el-form-item v-if="editParams.filedName == 'projectType'" class="edit-input-container" prop="projectType" label="客户划分:">
                <el-select ref="projectType" class="edit-input" v-model="editRuleForm.projectType" placeholder="请选择" @blur="() => {editCancelBtn('projectType')}" @change=" (e) => { editSeaDetail('projectType') }" filterable>
                  <el-option label="项目" :value="217"></el-option>
                  <el-option label="散号" :value="218"></el-option>
                </el-select>
              </el-form-item>
              <div @click=" () => { editBtn('projectType', customerInfo.projectType) } " v-if="editParams.filedName != 'projectType'" class="hoverEdit" >
                客户划分: <span class="editColor">{{(customerInfo && customerInfo.projectType) || "--"}}</span>
              </div>

              <el-form-item v-if="customerInfo.projectType == '项目' && editParams.filedName == 'projectName'" class="edit-input-container" prop="projectName" label="项目名称:">
                <el-input ref="projectName" class="edit-input" placeholder="请输入内容" v-model="editRuleForm.projectName" @blur="() => {editCancelBtn('projectName')}">
                  <template slot="append">
                    <div @click="() => {editSeaDetail('projectName')}" class="cancel">确认</div>
                  </template>
                </el-input>
              </el-form-item>
              <div @click="() => { editBtn('projectName', customerInfo.projectName)}" v-if="customerInfo.projectType == '项目' && editParams.filedName != 'projectName'" class="hoverEdit">
                项目名称:
                <span class="editColor">{{(customerInfo && customerInfo.projectName) || "--"}}</span>
              </div> -->

              <div @click="() => { projectShow() }" class="hoverEdit">
                <span class="noteName">客户划分:</span>
                <span class="editColor">{{(customerInfo && customerInfo.projectType) || "--"}}</span>
              </div>
              <div v-if="customerInfo.projectType == '项目'" @click="() => { projectShow() }" class="hoverEdit">
                <span class="noteName">项目名称:</span>
                <span class="editColor">{{(customerInfo && customerInfo.projectName) || "--"}}</span>
              </div>
              <div @click="() => { phoneWxShow() }" class="noteHoverEdit">
                <span class="noteName">联系方式:</span>
                <span class="editColor">{{(customerInfo && customerInfo.phoneWx) || "--"}}</span>
              </div>

            </div>
            <div class="borderLine"></div>
            <div class="data-content">
              <div class="data-title">联系信息</div>
              <div class="detail-content">
                <!-- <el-form-item
                  v-if="editParams.filedName == 'wx'"
                  class="edit-input-container"
                  prop="wx"
                  label="微信:"
                >
                  <el-input class="edit-input" ref="wx" placeholder="请输入内容" v-model="editRuleForm.wx" @blur="() => {editCancelBtn('wx')}">
                    <template slot="append">
                      <div @click="() => {editSeaDetail('wx')}" class="cancel">确认</div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('wx', customerInfo.wx);
                    }
                  "
                  v-if="editParams.filedName != 'wx'"
                  class="hoverEdit"
                >
                  微信:
                  <span class="editColor">{{
                    (customerInfo && customerInfo.wx) || "--"
                  }}</span>
                </div> -->
                <el-form-item
                  v-if="editParams.filedName == 'qq'"
                  class="edit-input-container"
                  prop="qq"
                  label="QQ:"
                >
                  <el-input
                   ref="qq"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model="editRuleForm.qq"
                    @blur="() => {editCancelBtn('qq')}"
                  >
                    <template slot="append">
                      <div @click="() => {editSeaDetail('qq')}" class="cancel">
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('qq', customerInfo.qq);
                    }
                  "
                  v-if="editParams.filedName != 'qq'"
                  class="hoverEdit"
                >
                  QQ:
                  <span class="editColor">{{
                    (customerInfo && customerInfo.qq) || "--"
                  }}</span>
                </div>
                <el-form-item
                  v-if="editParams.filedName == 'web'"
                  class="edit-input-container"
                  prop="web"
                  label="公司网址:"
                >
                  <el-input
                  ref="web"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model="editRuleForm.web"
                    @blur="
                      () => {
                        editCancelBtn('web');
                      }
                    "
                  >
                    <template slot="append"
                      ><div
                        @click="
                          () => {
                            editSeaDetail('web');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('web', customerInfo.web);
                    }
                  "
                  v-if="editParams.filedName != 'web'"
                  class="hoverEdit"
                >
                  公司网址:
                  <span class="editColor">{{
                    (customerInfo && customerInfo.web) || "--"
                  }}</span>
                </div>
                <el-form-item
                  v-if="editParams.filedName == 'mail'"
                  class="edit-input-container"
                  prop="mail"
                  label="邮箱:"
                >
                  <el-input
                  ref="mail"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model="editRuleForm.mail"
                    @blur="
                      () => {
                        editCancelBtn('mail');
                      }
                    "
                  >
                    <template slot="append"
                      ><div
                        @click="
                          () => {
                            editSeaDetail('mail');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('mail', customerInfo.mail);
                    }
                  "
                  v-if="editParams.filedName != 'mail'"
                  class="hoverEdit"
                >
                  邮箱:
                  <span class="editColor">{{
                    (customerInfo && customerInfo.mail) || "--"
                  }}</span>
                </div>
                <el-form-item
                  v-if="editParams.filedName == 'note'"
                  class="edit-input-container"
                  prop="note"
                  label="备注:"
                >
                  <el-input
                  ref="note"
                    class="edit-input"
                    placeholder="请输入内容"
                    v-model="editRuleForm.note"
                    @blur="
                      () => {
                        editCancelBtn('note');
                      }
                    "
                  >
                    <template slot="append"
                      ><div
                        @click="
                          () => {
                            editSeaDetail('note');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div></template
                    >
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('note', customerInfo.note);
                    }
                  "
                  v-if="editParams.filedName != 'note'"
                  class="noteHoverEdit"
                >
                  <span class="noteName">备注:</span>
                  
                  <span class="editColor">{{
                    (customerInfo && customerInfo.note) || "--"
                  }}</span>
                </div>
              </div>
            </div>
          </el-form>
          <div class="data-content">
            <div class="data-title">系统信息</div>
            <div class="detail-content">
              <div class="DisableEdit">
                创建人:
                <span class="common-clr-0a6">{{
                  (customerInfo && customerInfo.createName) || "--"
                }}</span>
              </div>
              <div class="DisableEdit">
                客户负责人所属部门:
                <span class="common-clr-0a6">{{
                  (adminInfo && adminInfo.userDepartName) || "--"
                }}</span>
              </div>
              <div class="DisableEdit">
                创建时间:
                <span class="common-clr-0a6">{{
                  (customerInfo && customerInfo.createTime) || "--"
                }}</span>
              </div>
              <div class="DisableEdit">
                客户负责人:
                <span class="common-clr-0a6">{{
                  (tableRow && tableRow.phoneUserName) || "--"
                }}</span>
              </div>
              <div class="DisableEdit">
                最后修改人:
                <span class="common-clr-0a6">{{
                  (adminInfo && adminInfo.nickName) || "--"
                }}</span>
              </div>
              <div class="DisableEdit">
                最后修改时间:
                <span class="common-clr-0a6">{{
                  (customerInfo && customerInfo.updateTime) || "--"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="drawer-right">
          <div class="rightTop">
            <div>
              <img class="visitIcon" src="../../../assets/images/notice_img.png" />
              任务提醒
            </div>
            <div v-if="!planTask.length" class="notContent">暂无提醒</div>
            <div v-else v-for="(item, index) in planTask" :key="index" class="notContent">
              {{ item.content }}
            </div>
          </div>
          <div style="flex: 1;overflow: scroll;">
            <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="工作记录" name="about">
                <div class="about-content">
                  <div style="white-space: nowrap;">
                     跟进类型:
                    <el-select class="flowType" v-model="recordRadio" placeholder="请选择跟进类型" >
                      <el-option :key="1" label="电话拜访" :value="1"> </el-option>
                      <el-option :key="2" label="网络拜访" :value="2"> </el-option>
                      <el-option :key="3" label="上门拜访" :value="3"> </el-option>
                    </el-select>
                  </div>
                  <br/>
                 <textarea class="common-textarea-log"  @paste="handlePaste" v-model="recordContent" placeholder="工作小计" rows="6"></textarea>
                  <!-- <el-input class="con-rec" :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent"></el-input> -->
                  <!-- <div v-for="(item, index) in filesList" :key="index" class="fileNames">
                    <div>
                      <img v-if="item.fileType != 'img'" style="margin-right: 8px" src="../../../assets/images/img_icon.svg"/>
                      <img v-if="item.fileType == 'img'" style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{ item.fileName }}
                    </div>
                    <div style="cursor: pointer;" @click="() => deleteFile(item.fileId)">删除</div>
                  </div> -->
                  <div class="fileNames" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="item.fileType" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteFile(item.fileId)">删除</div>
                  </div>
                  <div class="con-rec">
                    <div>
                      <div class="addFileBtn">
                        <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg"/>
                        添加附件
                        <input class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                      </div>
                      <div style="font-size: 12px; color: #ccc">
                        上传文件及图片，大小不超过10M
                      </div>
                    </div>
                    <el-button
                      class="common-screen-btn"
                      type="primary"
                      @click="() => addFollow(0)"
                      >发布</el-button
                    >
                  </div>
                  <br/>
                </div>
                <common-work-log :followRecordList="followRecordList"></common-work-log>
                <!-- <div v-if="chanceFollowList && chanceFollowList.length" class="record-container">
                  <div v-for="(item, index) in chanceFollowList" :key="index" class="record-item">
                    <div class="item-top">
                      <div class="item-icon"></div>
                      <span class="item-role"> {{ item.nick_name || '--' }} </span>
                      <span class="item-date"> {{ item.time || '--' }} </span>
                    </div>
                    <div v-for="(twoItem, twoIndex) in item.value" :key="twoIndex" class="item-content">
                      <div v-if="twoIndex == 0" class="item-detail">{{twoItem}}</div>
                      <div v-else>
                        <div v-for="(val, key) in twoItem" :key="key" class="item-detail">
                          <div v-if="key != '跟进文件' && key != '反馈文件' && key != '文件地址' && key != '下载链接'">
                            {{key}}: {{val}}
                          </div>
                          <div v-if="key == '下载链接' || key == '文件地址'">
                            <div @click="() => downLoad(val)" class="fileNames"><img style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{val}}</div>
                          </div>
                          <div v-if="key == '跟进文件' || key == '反馈文件' || key == '文件地址'">
                            <div v-for="(fileItem, fileIndex) in val" :key="fileIndex">
                              <img v-if="fileItem['文件类型'] == '图片'" @click="() => downLoad(fileItem['下载链接'])" class="fileImg" :src="fileItem['下载链接']" alt="">
                              <div v-else @click="() => downLoad(fileItem['下载链接'])" class="fileNames"><img style="margin-right: 8px" src="../../../assets/images/file_icon.svg" />{{fileItem['文件名称']}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </el-tab-pane>
              <el-tab-pane label="下次跟进计划" name="data">
                <div>
                  <el-button class="common-screen-btn" @click="() => {planAdd()}" type="primary">新建计划</el-button>
                  <div v-for="(item, index) in planList" :key="index" class="record-item">
                    <div class="item-top">
                      <div class="item-icon"></div>
                      <span class="item-role"> {{ item.planName }} </span>
                      <span class="item-date"> {{ item.createTime }} </span>
                    </div>
                    <div class="item-content">
                      <div>
                        <div v-if="item.sortLevel == 1" class="itemBtn">较低</div>
                        <div v-if="item.sortLevel == 2" class="itemBtn">普通</div>
                        <div v-if="item.sortLevel == 3" class="itemBtn">高优</div>
                        <div v-if="item.followType == 1" class="itemBtn">电话拜访</div>
                        <div v-if="item.followType == 2" class="itemBtn">网络拜访</div>
                        <div v-if="item.followType == 3" class="itemBtn">上门拜访</div>
                      </div>
                      <div v-if="item.planTime" class="item-detail">拜访日期：{{ item.planTime }}<img class="visitIcon" src="../../../assets/images/visit_icon.png" /></div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog v-dialogDrag title="新建跟进计划" :append-to-body="true" :visible.sync="planVisible" :close-on-click-modal="false" width="520px">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <el-form :model="planParams" :rules="planRules" ref="planParams" label-width="110px" class="demo-ruleForm">
          <el-form-item label="计划名称" prop="planName">
            <el-input class="dialog-input" v-model="planParams.planName" maxLength="50" placeholder="请填写计划名称"></el-input>
          </el-form-item>
          <el-form-item label="跟进类型" prop="serviceId">
            <el-select class="dialog-input" v-model="planParams.followType" placeholder="请选择跟进类型" >
              <el-option :key="1" label="电话拜访" :value="1"> </el-option>
              <el-option :key="2" label="网络拜访" :value="2"> </el-option>
              <el-option :key="3" label="上门拜访" :value="3"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优先级" prop="serviceId">
            <el-select class="dialog-input" v-model="planParams.sortLevel" placeholder="请选择优先级" >
              <el-option :key="1" label="较低" :value="1"> </el-option>
              <el-option :key="2" label="普通" :value="2"> </el-option>
              <el-option :key="3" label="高优" :value="3"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="拜访日期" prop="expectedTime">
            <el-date-picker class="dialog-input" v-model="planParams.planTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间" :picker-options="pickerOptions"></el-date-picker>
          </el-form-item>
          <el-form-item label="提醒时间" prop="serviceId">
            <el-select class="dialog-input" v-model="planParams.remind" placeholder="请选择提醒时间" filterable>
              <el-option :key="5" label="开始前五分钟" :value="5"> </el-option>
              <el-option :key="15" label="开始前十五分钟" :value="15"> </el-option>
              <el-option :key="30" label="开始前三十分钟" :value="30"> </el-option>
              <el-option :key="60" label="开始前一小时" :value="60"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input class="dialog-input" type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" v-model="planParams.remark" maxLength="500" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="planVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" @click="addNextPlan('planParams')" type="primary" >确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="联系方式" :append-to-body="true" :visible.sync="phoneWxVisible" :close-on-click-modal="false" width="520px">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <el-form :model="phoneWxRuleForm" ref="phoneWxRuleForm" :rules="phoneWxRules" label-width="120px" class="demo-ruleForm">
          <el-form-item v-for="(item, index) in phoneWxRuleForm.phoneArr" :key="index" :prop="`phoneArr[${index}].value`" :rules="item.type == 'phone' ? phoneWxRules.phone : phoneWxRules.wx">
            <template slot="label">
              <el-select v-model="item.type" style="width: 70%">
                <el-option label="手机" value="phone"></el-option>
                <el-option label="微信" value="wx"></el-option>
              </el-select>
            </template>
            <el-input class="dialog-input" v-model="item.value" maxLength="50" placeholder="请填写"></el-input>
            <span v-if="index == 0" class="el-icon-circle-plus-outline" @click="() => { addPhoneFun() }"></span>
            <span v-if="index != 0" class="el-icon-delete" @click="() => { deletePhoneFun(index) }"></span>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="phoneWxVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => submitForm('phoneWxRuleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="客户划分" :append-to-body="true" :visible.sync="projectVisible" :close-on-click-modal="false" width="520px">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <el-form :model="projectForm" ref="projectForm" :rules="projectRules" label-width="120px" class="demo-ruleForm">
          <el-form-item label="客户划分" prop="projectType">
            <el-select class="dialog-input" v-model="projectForm.projectType" placeholder="请选择" filterable >
              <el-option label="项目" :value="217" ></el-option>
              <el-option label="散号" :value="218" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="projectForm.projectType == 217" label="项目名称" prop="projectName">
            <el-input class="dialog-input" v-model="projectForm.projectName" maxLength="50" placeholder="请输入项目名称" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="projectVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => projectSubmitForm('projectForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addFollow, addNextPlan, nextPlanLst, editSeaDetail, planTaskList, } from "../../../service/customer.js";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { upload } from "../../../service/upload.js";
import { workLog, sysArea } from "../../../service/common.js"
import { Config } from "../../../utils/index.js"
export default {
  components: { CommonWorkLog, CommonPicture },
  props: [
    "drawer",
    "tableRow",
    "customerInfo",
    "adminInfo",
    "chanceFollowList",
    "custDict",
    "loading",
  ],
  data() {
    let ruleAccount = (rule, value, callback) => {
      let reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (value) {
        value = value.replace(/[\u202D+\u202C+\s+]/g, "");
        if (!reg.test(value)) {
          callback(new Error("手机号格式不正确!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let isEmaill = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value) {
        if (!reg.test(value)) {
          return callback(new Error("邮箱格式不正确!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      props: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node;
          let typeId
          if (level == 0) {
            typeId = 0
          } else {
            typeId = value
          }
          let resData = (await sysArea({id: typeId})).data
          let nodes = resData.map((item) => ({
              value: item.id + '',
              label: item.name,
              leaf: level >= 2
            }))
          resolve(nodes)
        }
      },
      followRecordList: [], // 工作记录
      isShowInput: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - (1000 * 60 * 60 * 24);
        },
      },
      filesList: [],
      editParams: {
        filedName: "",
        filedValue: "",
      },
      planVisible: false, // 新建计划弹窗
      input2: "",
      activeName: "about", // tabs
      recordRadio: 1, // 跟进记录radio
      recordContent: "", // 跟进记录内容
      planParams: {}, // 新建计划提交内容
      planRules: {
        planName: [{ required: true, message: "请填写计划名称", trigger: "change" }],
      },
      editRules: {
        // address: [{ required: true, message: "请填写地址", trigger: "change" }],
        // phone: [{ validator: ruleAccount, trigger: "change" }],
        mail: [{ validator: isEmaill, trigger: "change" }],
      },
      editRuleForm: {
        address: "",
        markingStatus: "",
        web: "",
        note: "",
        mail: "",
        qq: "",
        wx: "",
        phone: "",
        industryName: "",
        industry: "",
        source: "",
        level: "",
        seaName: "",
        seaType: "",
        name: "",
        projectName: '',
        projectType: ''
      },
      projectVisible: false,
      projectForm: {
        projectName: '',
        projectType: ''
      },
      projectRules: {
        projectType: [{ required: true, message: "请选择客户划分", trigger: "change" }],
        projectName: [{ required: true, message: "请填写项目名称", trigger: "change" }],
        
      },
      planList: [], // 计划列表
      planTask: [], // 提醒列表
      phoneWxRuleForm: {}, // 联系方式编辑
      phoneWxRules: { // 联系方式校验
        phone: [{ validator: ruleAccount, trigger: "change" },{ required: true, message: "请填写手机号", trigger: "change" }],
        wx: [{ required: true, message: "请填写微信号", trigger: "change" }],
      },
      phoneWxVisible: false,
    };
  },
  methods: {
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;

      if (!items || items.length === 0) {
        this.$message.error("当前浏览器不支持本地");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        // this.$message.error("粘贴内容非图片");
        return;
      }
      this.chooseFile(file, 'copy')
    },
    projectSubmitForm(formName) { // 编辑客户划分-项目
      let projectForm = {...this.projectForm};
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$emit("loadFun", true);
          await editSeaDetail({customerId: this.tableRow.id, filedName: 'projectType', filedValue: projectForm.projectType});
          if (projectForm.projectType == 217 ) {
            await editSeaDetail({customerId: this.tableRow.id, filedName: 'projectName', filedValue: projectForm.projectName});
          }
          this.$emit("loadFun", false);
          this.projectVisible = false
          this.$emit("getCustDeatil", this.tableRow);
        } else {
          return false;
        }
      });
    },
    submitForm(formName) { // 编辑联系方式
      let phoneArr = [...this.phoneWxRuleForm.phoneArr];
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let phone = []
          let wx = []
          phoneArr.map((item) => {
            if(item.type == 'phone') {
              phone.push(item.value)
            } else {
              wx.push(item.value)
            }
          })
          phone = phone.join(',')
          wx = wx.join(',')
          this.$emit("loadFun", true);
          await editSeaDetail({customerId: this.tableRow.id, filedName: 'phone', filedValue: phone});
          await editSeaDetail({customerId: this.tableRow.id, filedName: 'wx', filedValue: wx});
          this.$emit("loadFun", false);
          this.phoneWxVisible = false
          this.$emit("getCustDeatil", this.tableRow);
        } else {
          return false;
        }
      });
    },
    projectShow() { // 编辑客户划分弹窗-项目
      let customerInfo = {...this.customerInfo}
      this.projectForm = {
        projectType: customerInfo.projectType == "项目" ? 217 : 218,
        projectName: customerInfo.projectName
      }
      this.projectVisible = true;
    },
    phoneWxShow() { // 编辑联系方式弹窗
      let customerInfo = {...this.customerInfo}
      let phone = customerInfo.phone
      let wx = customerInfo.wx
      let newArr = []
      if (phone) {
        phone = phone.split(",")
        phone.map((item) => {
          newArr.push({type: 'phone', value: item})
        })
      }
      if (wx) {
        wx = wx.split(",")
        wx.map((item) => {
          newArr.push({type: 'wx', value: item})
        })
      }
      this.phoneWxRuleForm = {
        phoneArr: newArr
      };
      if (this.$refs["phoneWxRuleForm"]) this.$refs["phoneWxRuleForm"].resetFields();
      this.phoneWxVisible = true;
    },
    addPhoneFun() { // 添加一条目手机号
      this.phoneWxRuleForm.phoneArr.push({type: 'phone', value: ''})
    },
    deletePhoneFun(i) { // 删除一条目手机号
      this.phoneWxRuleForm.phoneArr = this.phoneWxRuleForm.phoneArr.filter((item, index) => {
        return index != i
      })
    },
    async workLog(id) { // 工作日志
      let resData = (await workLog({id: id, type: -1})).data
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = resData
    },
    downLoad(url) {
      // 下载文件
      window.location.href = url;
    },
    deleteFile (id) {
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    async chooseFile(e, type) { // 上传文件
      let file
      if (type != 'copy') {
        file = e.target.files[0];
      } else {
        file = e
      }
      // let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        if (type != 'copy') { e.target.value = "" }
       return this.$message.error('文件大小不能超过50M')
      }
      this.$emit("loadFun", true);
      let resData = (await upload({ file: file })).data;
      this.$emit("loadFun", false);
      let fileItem = {
        fileType: Config.fileType.imgType.includes(file.name.split('.')[file.name.split('.').length - 1]) ? 'imgText' : 'file',
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      // if (
      //   format != "bmp" &&
      //   format != "jpg" &&
      //   format != "png" &&
      //   format != "gif" &&
      //   format != "jpeg"
      // ) {
      //   fileItem.fileType = "img";
      // }
      this.filesList.push(fileItem);
      if (type != 'copy') { e.target.value = "" }
    },
    async planTaskList(id) {
      // 计划提醒列表
      let resData = (await planTaskList({ customerId: id })).data;
      this.planTask = resData;
    },
    editSeaDetail(name) {
      // 确认-提交修改
      let params = {
        customerId: this.tableRow.id,
        filedName: name,
        filedValue: this.editRuleForm[name],
      };
      if (this.editParams.filedName) {
        this.$emit("loadFun", true);
        this.$refs["editRuleForm"].validateField(name, async (error) => {
          if (!error) {
            if (name == 'areaId') {
              params.filedValue = params.filedValue.join(',')
            }
            await editSeaDetail(params);
            this.$emit("getCustDeatil", this.tableRow);
            this.editParams = {
              filedName: "",
              filedValue: "",
            };
          } else {
            this.$emit("loadFun", false);
            return false;
          }
        });
      }
    },
    editCancelBtn(name) {
      // 取消编辑
      setTimeout(() => {
        if (this.isShowInput == name) {
          this.editParams = {
            filedName: "",
            filedValue: "",
          };
          this.isShowInput = ''
        }
      }, 500)
    },
    editBtn(name, value) {
      this.$refs["editRuleForm"].validate(async (valid) => {
        if (valid) {
          // this.promiseFun(name, value)
          new Promise((resolve) => {
            this.editParams = {
              filedName: name,
              filedValue: value,
            };
            this.editRuleForm[name] = value;
            resolve()
          }).then(() => {
            if (this.$refs[name]) {
              this.$refs[name].focus()
            }
          }).then(() => {
            this.isShowInput = name
          })
        } else {
          return;
        }
      });
    },
    async nextPlanLst(id) {
      // 下次跟进记录
      let resData = (await nextPlanLst({}, id)).data;
      this.planList = resData;
    },
    async addNextPlan(formName) {
      // 新建下次跟进计划提交数据
      this.$emit("loadFun", true);
      let planParams = { ...this.planParams };
      planParams.customerId = this.tableRow.id;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await addNextPlan(planParams);
          this.nextPlanLst(this.tableRow.id);
          this.planTaskList(this.tableRow.id);
          this.$message.success("新建成功");
          this.planVisible = false;
          this.$emit("loadFun", false);
        } else {
          this.$emit("loadFun", false);
          return false;
        }
      });
      //
    },
    planAdd() {
      // 新建计划按钮
      this.planParams = {};
      this.planVisible = true;
      if (this.$refs.planParams) {
        this.$refs.planParams.resetFields();
      }
    },
    async addFollow() {
      // 跟进发布按钮
      let filesList = [...this.filesList]
      let filesUrl = []
      let filesId = []
      let filesName = []
      filesList.map((item) => {
        filesUrl.push(item.fileUrl)
        filesId.push(item.fileId)
        filesName.push(item.fileName)
      })
      let params = {
            followType: this.recordRadio,
            followContent: this.recordContent,
            customerId: this.tableRow.id,
            fileIds: filesId.join(','),
            fileUrl: filesUrl.join(','),
            fileNames: filesName.join(',')
          };
      await addFollow(params);
      this.filesList = []
      this.$emit("getCustDeatil", this.tableRow);
      this.recordContent = "";
      this.recordRadio = 1;
      this.$message.success("添加成功");
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    handleClick() {
      console.log(this.activeName);
    },
  },
};
</script>
<style lang="less" scoped>
.el-icon-circle-plus-outline, .el-icon-delete {
  color: #1890ff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
}
.seaDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .flowType {
    width: 400px;
  }
  .borderLine {
    position: relative;
    width: 100%;
    margin-top: 14px;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      background: #eaeaeb;
      height: 1px;
      width: 120%;
      left: -10%;
      top: 0;
    }
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      cursor: pointer;
    }
    .fileImg {
      width: 80px;
      margin-top: 8px;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
    }
    .drawer-right {
      width: 500px;
      overflow-y: auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .rightTop {
        border-bottom: 1px #eaeaeb solid;
        padding: 24px;
        font-size: 14px;
        color: #101010;
        height: 112px;
        box-sizing: border-box;
        .visitIcon {
          width: 14px;
          vertical-align: middle;
          margin-right: 8px;
        }
        .notContent {
          margin-top: 24px;
        }
      }
      /deep/ .el-tabs__content {
        padding-left: 16px;
      }
      .record-item {
        margin-top: 16px;
        border-top: 1px #eaeaeb dashed;
        padding-top: 16px;
        .item-content {
          margin-left: 22px;
          .itemBtn {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 14px;
            border: 1px solid #1890ff;
            border-radius: 4px;
            font-size: 14px;
            color: #101010;
            margin: 16px 16px 8px 0;
          }
          .item-detail {
            color: #999;
            margin-top: 8px;
            vertical-align: middle;
            .visitIcon {
              width: 14px;
              margin-left: 8px;
            }
          }
        }
        .item-top {
          padding-right: 16px;
          .item-icon {
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            border: 4px #1890ff solid;
            border-radius: 50%;
            margin-right: 8px;
          }
          .item-date {
            float: right;
            color: #999;
          }
        }
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail-tit-info {
        font-size: 18px;
      }
      .hoverEdit {
        display: inline-block;
        width: 303px;
        font-size: 18px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
          font-size: 18px;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
    }
    .detail-content {
      .edit-input-container {
        display: inline-block;
        width: 303px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input {
        width: 220px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
        .cancel {
          cursor: pointer;
        }
        /deep/ .el-input__inner,
        .el-input-group__append {
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input-group__append {
          height: 30px;
          line-height: 30px;
        }
      }
      .noteHoverEdit {
        display: inline-flex;
        width: 580px;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99% center
            #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 20px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95% center
            #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
      }
    }
    .about-content {
      padding-top: 16px;
      // height: 300px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
          }
        }
      }
    }
    .data-content {
      .detail-content {
        margin-top: 16px;
      }
      .data-title {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #000000a6;
        border-bottom: 1px dashed #ccc;
        margin-top: 16px;
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
.dialog-input {
  width: 320px !important;
}
.mt{
  margin-top: 16px;
  border: 1px solid #1890ff;
  color: #1890ff;
}
</style>
